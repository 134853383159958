var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{ref:"rodadasTable",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('draggable',_vm._b({on:{"end":function($event){return _vm.updateData(_vm.rodadas)}},model:{value:(_vm.rodadas),callback:function ($$v) {_vm.rodadas=$$v},expression:"rodadas"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.rodadasPerPage),function(rodada,idx){return _c('tr',{key:rodada.key},[_c('td',[_c('strong',[_vm._v(_vm._s(idx + 1)+"ª")])]),_c('td',[_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center"}},[_vm._l((_vm.atributosList),function(atributo){return _c('v-col',{key:atributo + idx,staticClass:"py-1 px-0",attrs:{"cols":_vm.atributosCols,"sm":_vm.atributosSm}},[_c('conciliacoes-check-box',{attrs:{"label":_vm._f("replaceUnderscoresWithSpace")(atributo),"value":atributo,"arrayValues":_vm.rodadas[idx].valores,"readonly":atributo == 'TUC' && _vm.tipoConciliacao == 'EXPRESS'
                          ? true
                          : false},on:{"update:arrayValues":function($event){return _vm.$set(_vm.rodadas[idx], "valores", $event)},"update:array-values":function($event){return _vm.$set(_vm.rodadas[idx], "valores", $event)},"change":function($event){return _vm.updateData(_vm.rodadas)},"~click":function($event){atributo == 'TUC' && _vm.tipoConciliacao == 'EXPRESS'
                          ? _vm.$toast.warning(
                              'O atributo <b>TUC</b> deve estar presente em todas as rodadas.',
                              '',
                              { position: 'topRight', timeout: 3000 }
                            )
                          : ''}}})],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('input',{attrs:{"type":"hidden","name":("validar-rodada-" + idx)},domProps:{"value":_vm.rodadas[idx].valores}}),(errors.length)?_c('div',{staticClass:"v-messages theme--light error--text v-messages__message"},[_vm._v(" Selecione pelo menos 1 atributo ")]):_vm._e()]}}],null,true)})],1)],2)],1),_c('td',[_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":_vm.actionBtnsSm}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.adicionarRodadaFinal(rodada)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-plus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Adicionar uma nova rodada ao final da lista")])])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":_vm.actionBtnsSm}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.duplicarRodada(idx)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-plus-box-multiple")])],1)]}}],null,true)},[_c('span',[_vm._v("Duplica a rodada preservando sua posição")])])],1),(_vm.rodadas.length > 1)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":_vm.actionBtnsSm}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.removerRodada(idx)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Remover Rodada")])])],1):_vm._e()],1)],1)])}),0)],1)]},proxy:true}])}),(_vm.page > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticStyle:{"font-weight":"200","font-size":"14px"}},[_vm._v(" Exibindo "),_c('b',[_vm._v(_vm._s(_vm.page * 10 > _vm.rodadas.length ? _vm.rodadas.length : _vm.page * 10))]),_vm._v(" de "),_c('b',[_vm._v(_vm._s(_vm.rodadas.length))]),_vm._v(" rodadas "),(_vm.page * 10 < _vm.rodadas.length)?_c('a',{on:{"click":function($event){return _vm.$emit('update:page', _vm.page + 1)}}},[_vm._v(" mostrar mais ")]):(_vm.rodadas.length > 10)?_c('a',{on:{"click":function($event){return _vm.$emit('update:page', 1)}}},[_vm._v(" ocultar rodadas ")]):_vm._e()])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }