<template>
  <v-row align="center">
    <v-col cols="12">
      <v-simple-table
        dense
        ref="rodadasTable"
      >
        <template v-slot:default>
          <tbody>
            <draggable
              v-bind="dragOptions"
              v-model="rodadas"
              @end="updateData(rodadas)"
            >
              <tr
                v-for="(rodada, idx) in rodadasPerPage"
                :key="rodada.key"
              >
                <td>
                  <strong>{{ idx + 1 }}ª</strong>
                </td>
                <td>
                  <v-row
                    align="center"
                    class="mx-auto"
                  >
                    <v-col
                      v-for="atributo in atributosList"
                      :key="atributo + idx"
                      :cols="atributosCols"
                      :sm="atributosSm"
                      class="py-1 px-0"
                    >
                      <conciliacoes-check-box
                        :label="atributo | replaceUnderscoresWithSpace"
                        :value="atributo"
                        :arrayValues.sync="rodadas[idx].valores"
                        :readonly="
                          atributo == 'TUC' && tipoConciliacao == 'EXPRESS'
                            ? true
                            : false
                        "
                        @change="updateData(rodadas)"
                        @click.once="
                          atributo == 'TUC' && tipoConciliacao == 'EXPRESS'
                            ? $toast.warning(
                                'O atributo <b>TUC</b> deve estar presente em todas as rodadas.',
                                '',
                                { position: 'topRight', timeout: 3000 }
                              )
                            : ''
                        "
                      />
                    </v-col>
                    <v-col cols="12">
                      <validation-provider
                        immediate
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <input
                          type="hidden"
                          :name="`validar-rodada-${idx}`"
                          :value="rodadas[idx].valores"
                        />
                        <div
                          v-if="errors.length"
                          class="v-messages theme--light error--text v-messages__message"
                        >
                          Selecione pelo menos 1 atributo
                        </div>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </td>
                <td>
                  <v-row
                    align="center"
                    class="mx-auto"
                  >
                    <v-col
                      cols="12"
                      :sm="actionBtnsSm"
                      class="pa-0"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="adicionarRodadaFinal(rodada)"
                            color="success"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon size="22">mdi-plus-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Adicionar uma nova rodada ao final da lista</span>
                      </v-tooltip>
                    </v-col>
                    <v-col
                      cols="12"
                      :sm="actionBtnsSm"
                      class="pa-0"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="duplicarRodada(idx)"
                            color="success"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon size="22">mdi-plus-box-multiple</v-icon>
                          </v-btn>
                        </template>
                        <span>Duplica a rodada preservando sua posição</span>
                      </v-tooltip>
                    </v-col>
                    <v-col
                      v-if="rodadas.length > 1"
                      cols="12"
                      :sm="actionBtnsSm"
                      class="pa-0"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="removerRodada(idx)"
                            color="error"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon size="22">mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Remover Rodada</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </draggable>
          </tbody>
        </template>
      </v-simple-table>
      <v-col
        cols="12"
        v-if="page > 0"
      >
        <p style="font-weight: 200; font-size: 14px">
          Exibindo
          <b>{{ page * 10 > rodadas.length ? rodadas.length : page * 10 }}</b>
          de <b>{{ rodadas.length }}</b> rodadas
          <a
            v-if="page * 10 < rodadas.length"
            @click="$emit('update:page', page + 1)"
          >
            mostrar mais
          </a>
          <a
            v-else-if="rodadas.length > 10"
            @click="$emit('update:page', 1)"
          >
            ocultar rodadas
          </a>
        </p>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'RodadasConciliacoes',

  components: {
    draggable: () => import('vuedraggable'),
    ConciliacoesCheckBox: () =>
      import('@/components/power-ups/conciliacoes/ConciliacoesCheckBox.vue')
  },

  props: {
    value: {
      type: Array,
      required: true
    },
    page: {
      type: Number,
      required: false,
      default: 0
    },
    atributosList: {
      type: Array,
      required: true
    },
    tipoConciliacao: {
      type: String,
      required: true
    },
    atributosCols: {
      type: String,
      required: false,
      default: 'auto'
    },
    atributosSm: {
      type: String,
      required: false,
      default: 'auto'
    },
    actionBtnsSm: {
      type: String,
      required: false,
      default: '12'
    }
  },

  computed: {
    dragOptions() {
      return {
        animation: 300,
        ghostClass: 'ghost'
      };
    },
    rodadasPerPage() {
      return this.page > 0
        ? this.rodadas.slice(0, this.page * 10)
        : this.rodadas;
    },
    rodadas: {
      get() {
        return JSON.parse(JSON.stringify(this.value));
      },
      set(newValue) {
        this.updateData(newValue);
      }
    }
  },

  methods: {
    duplicarRodada(idx) {
      this.rodadas.splice(idx + 1, 0, {
        key: this.rodadas.length + 1,
        valores: this.rodadas[idx].valores
      });
      this.updateData(this.rodadas);
    },
    adicionarRodadaFinal(rodada) {
      this.rodadas.push({
        key: this.rodadas.length + 1,
        valores: rodada.valores
      });
      this.updateData(this.rodadas);
    },
    removerRodada(idx) {
      this.rodadas.splice(idx, 1);
      this.updateData(this.rodadas);
    },
    updateData(newRodadas) {
      this.$emit('input', newRodadas);
    }
  }
};
</script>

<style>
.ghost {
  opacity: 0.5;
}
.expansion-panel-padding-x-clear .v-expansion-panel-content__wrap {
  padding-left: 0.4px !important;
  padding-right: 0.4px !important;
}
</style>
